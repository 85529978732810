<template>
    <div>
        <main-header></main-header>
        <div class="vld-parent">
            <loading :active.sync="isLoading"
                     :can-cancel="false"
                     :is-full-page="fullPage">
            </loading>
        </div>
        <breadcrumbs></breadcrumbs>

        <div class="wrapper wrapper--grey wrapper--full" id="contactForm">

            <div class="contact">
                <div class="contact__heading">
                    <h1>Contact Terrific.ie</h1>
                    <small>Please complete the form below with any queries or feedback you may have for Terrific.ie (all
                        fields are mandatory).</small>
                </div>
                <div class="contact__content">
                    <form method="post" @submit.prevent="sendEmail" @keydown="errors.clear($event.target.name)">
                        <fieldset>
                            <label for="name">Your Name*</label>
                            <input type="text" name="name" id="name" value="" class="form-control" v-model="name"
                                    :class="errors.has('name') ? 'form-control--error' : null"/>

                            <div v-if="errors.has('name')">
                            <div class="form-error"
                                  v-for="(error, index) in errors.get('name')" :key="index"
                                  v-text="error"></div>
                            </div>

                            <label for="email">Your Email Address*</label>
                            <input type="email" name="email" id="email" value="" class="form-control" v-model="email"
                                    :class="errors.has('email') ? 'form-control--error' : null"/>

                            <div v-if="errors.has('email')">
                            <div class="form-error"
                                  v-for="(error, index) in errors.get('email')" :key="index"
                                  v-text="error"></div>
                            </div>

                            <label for="phone">Your Phone Number*</label>
                            <input type="text" name="phone" id="phone" value="" class="form-control" v-model="phone"
                                    :class="errors.has('phone') ? 'form-control--error' : null"/>

                            <div v-if="errors.has('phone')">
                            <div class="form-error"
                                  v-for="(error, index) in errors.get('phone')" :key="index"
                                  v-text="error"></div>
                            </div>

                            <label for="comments">Comments</label>
                            <textarea name="comments" id="comments" rows="8" class="form-control"
                                      v-model="comments"
                                      :class="errors.has('comments') ? 'form-control--error' : null"></textarea>

                            <div v-if="errors.has('comments')">
                            <div class="form-error"
                                  v-for="(error, index) in errors.get('comments')" :key="index"
                                  v-text="error"></div>
                            </div>
                        </fieldset>
                        <input type="submit" value="Submit" :disabled="errors.any()"/>
                    </form>
                </div>
            </div>
        </div>
        <modal-status-message v-if="modalStatusMessageOpen" :msg="statusMessage"></modal-status-message>
        <main-footer></main-footer>
    </div>
</template>

<script>
    import MainHeader from '@/components/MainHeader.vue'
    import MainFooter from '@/components/MainFooter'
    import Breadcrumbs from '@/components/partials/Breadcrumbs'
    import ModalStatusMessage from '../components/partials/ModalStatusMessage'
    import Loading from 'vue-loading-overlay'
    import EventBus from '../event-bus'

    class Errors {
        constructor() {
            this.errors = {};
        }

        any() {
            return Object.keys(this.errors).length > 0;
        }

        has(field) {
            //return this.errors.hasOwnProperty(field);
            return Object.prototype.hasOwnProperty.call(this.errors, field);
        }

        get(field) {
            if (this.errors[field]) {
                return this.errors[field];
            }
        }

        record(errors) {
            this.errors = errors;
        }

        clear(field) {
            delete this.errors[field];
        }
    }

    export default {
        name: "ContactUs",
        components: {
            ModalStatusMessage,
            MainHeader,
            MainFooter,
            Breadcrumbs,
            Loading
        },
        mounted(){
            let that = this;
            EventBus.$on('closeModal', function () {
                that.modalStatusMessageOpen = false;
            })
        },
        data: function () {
            return {
                fullPage: true,
                isLoading: false,
                name: null,
                email: null,
                phone: null,
                comments: null,
                response: null,
                title: "Contact Us | Terrific.ie",
                description: "To contact us, please complete the form on this page with any queries or feedback you may have for Terrific.ie.",
                keywords: "Cars for Sale, Used Cars, Cars, Second Hand Cars, Electric Cars, Audi, BMW, Ford, Toyota, 7 Seaters, Land Rover",
                robots: "all",
                errors: new Errors(),
                statusMessage: null,
                modalStatusMessageOpen: false,
            }
        },
        serverPrefetch () {
            this.$ssrContext.title = this.title;
            this.$ssrContext.description = this.description;
            this.$ssrContext.keywords = this.keywords;
            this.$ssrContext.robots = this.robots;
        },
        methods: {
            openStatusMessageModal(params){
                this.statusMessage = params.msg;
                this.modalStatusMessageOpen = true;
            },
            scrollTop(){
                let elmnt = document.getElementById("contactForm");
                elmnt.scrollIntoView();
            },
            sendEmail() {
                this.isLoading = true;
                this.axios.post(process.env.VUE_APP_API_URL  + '/api/contact-form', {
                    'name': this.name,
                    'email': this.email,
                    'phone': this.phone,
                    'comments': this.comments,
                })
                    .then(response => {
                        this.isLoading = false;
                        this.openStatusMessageModal({msg: 'Your comments have been sent. Thank you!'});
                        // TODO extract to form object
                        this.name = null;
                        this.email = null;
                        this.phone = null;
                        this.comments = null;
                        this.response = response;
                        this.scrollTop();
                    })
                    .catch(error => {
                        this.isLoading = false;
                        this.errors.record(error.response.data.errors);
                        this.scrollTop();
                    });
            }
        }
    }
</script>

<style scoped>

</style>
