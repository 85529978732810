<template>
    <div class="modal" style="display: flex">
        <div class="modalform">
            <div class="modal__wopenForwardModalrapper">
                <div class="modal__heading" @click="closeModal">
                    <h1>{{ statusMsg }}</h1>
                </div>
                <div class="modalform__form">
                    <p>
                    {{ message }}
                    </p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import EventBus from "../../event-bus";

    export default {
        name: "ModalStatusMessage",
        props: ['msg'],
        data: function() {
            return {
                statusMsg: "Thank You!",
                message: "Your enquiry has been sent. Thank you!",
            }
        },
        mounted(){
            this.message = this.msg != null ? this.msg : this.message;
        },
        methods: {
            closeModal() {
                EventBus.$emit('closeModal');
            },
            openStatusMessageModal(){
                EventBus.$emit('openStatusMessageModal');
            }
        }
    }
</script>

<style scoped>
    h1{
        min-width: 250px;
    }
    .modalform__form {
        padding: 2rem 2rem 2rem 2rem !important;
    }
</style>
